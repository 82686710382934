/* global tumarket */

import { getCookie, setCookie } from "./global/cookie";
import { closeDialog, showDialog } from "./global/dialog";
import { show } from "./global/dom";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { isMob } from "./global/util";

function showWelcomeDialog(force, data, timerContext){
    if (!data) data = {};
    if (!timerContext) timerContext = 'default';

    let timerContextKey = `lastBannerShown_${timerContext}`;

    data.city = tumarket.general.location.name;
    let interval =  null;

    let key = `disableBanner2_${data.id}`;
    let bannerCloseInterval = tumarket.bannerCloseInterval;

    let _lastBannerShown = localStorage.getItem(timerContextKey);
    let lastBannerShown = null;
    if (_lastBannerShown) lastBannerShown = new Date(Number(_lastBannerShown));
    if (lastBannerShown && (new Date() - lastBannerShown) <= 60000) {
        show('[data-show-if-no-popup]');
        return;
    }

    let disable = getCookie(key);

    if (!force && disable) {
        show('[data-show-if-no-popup]');
        return;
    }

    return loadPrecompiledTemplates(['welcome-dialog']).then(() => {
        showDialog({
            content: getPrecompiledTemplate('welcome-dialog')(data),
            modal: 'welcome-dialog',
            pinToBottom: isMob(),
            onClosed: () => {
                setCookie(key, 1, bannerCloseInterval/24);
                clearInterval(interval);
            },
            onShown: () => {
                let disableButton = document.querySelector('#welcome-dialog [data-welcome-disable]');
                disableButton?.addEventListener('click', () => {
                    setCookie(key, 1, bannerCloseInterval/24);
                    closeDialog('#welcome-dialog');
                });

                let timerElement = document.querySelector('[data-welcome-timer]');
                let secondsLeft = 30;
                if (timerElement) {
                    timerElement.innerHTML = secondsLeft;
                    
                    interval = setInterval(() => {
                        secondsLeft--;
                        timerElement.innerHTML = secondsLeft;

                        if (!secondsLeft) {
                            clearInterval(interval);
                            closeDialog('#welcome-dialog');
                        }
                    }, 1000);
                }

                localStorage.setItem(timerContextKey, Number(new Date()));
            },
            showHeader: false,
            showFooter: false,
            size: 'sm',
            noBackdrop: true
        })
    });
}

export { showWelcomeDialog };